import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { Either, left, right } from 'fp-ts/lib/Either';

interface FailureResponse {
    code: string;
    message: string;
}

interface SuccessResponse<T> {
    code: '0000';
    data: T;
}

type Response<T> = SuccessResponse<T> | FailureResponse;
type AllowedMethod = Exclude<Method, 'purge' | 'PURGE' | 'link' | 'LINK' | 'unlink' | 'UNLINK'>;
type ErrorGenerator<T> = (_: FailureResponse) => T;

function isSuccessResponse<T>(response: Response<T>): response is SuccessResponse<T> {
    return response.code === '0000';
}

export class ApiClient {
    private readonly origin = location.origin;
    private readonly host = this.origin;
    private readonly baseUrl = `${this.host}/api`;
    private token: string | null = null;

    public setToken(token: string): void {
        this.token = token;
    }

    public clearToken(): void {
        this.token = null;
    }

    public async request<ResponseType = unknown, ParamType = unknown>(
        method: AllowedMethod,
        resource: string,
        data?: ParamType,
    ): Promise<Either<string, ResponseType>> {
        return this.requestWithError(method, resource, defaultGenerator, data);
    }

    public async requestWithError<ResponseType = unknown, ParamType = unknown, ErrorType = unknown>(
        method: AllowedMethod,
        resource: string,
        generator: ErrorGenerator<ErrorType>,
        data?: ParamType,
    ): Promise<Either<ErrorType, ResponseType>> {
        type APResponse<T> = AxiosPromise<Response<T>>;

        try {
            const url = `${this.baseUrl}/${resource}`;
            const options: Exclude<AxiosRequestConfig, 'method' | 'data'> =
                this.token == null ? {} : { headers: { authorization: `Bearer ${this.token}` } };
            const response = (
                await (axios(url, {
                    ...options,
                    method,
                    data,
                }) as APResponse<ResponseType>)
            ).data;

            return isSuccessResponse(response) ? right(response.data) : left(generator(response));
        } catch (e) {
            const error = (e as AxiosError<FailureResponse>).response;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return left(generator(error!.data));
        }
    }
}

const defaultGenerator: ErrorGenerator<string> = (error: FailureResponse): string => error.message;
