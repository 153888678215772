
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { GroupMember } from '@i/groupMember';
import { GroupRole } from '@i/groupRole';
import { authStore } from '@s/auth';
import { groupMemberStore } from '@s/groupMember';
import { groupRoleStore } from '@s/groupRole';
import GroupRoleDisplay from '@ca/GroupRole/GroupRoleDisplay.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-member-list',
    components: {
        GroupRoleDisplay,
        VButton,
    },
})
export default class GroupMemberListDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;

    @Emit() onGroupMemberAdd(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
    @Emit() onGroupMemberEdit(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
    @Emit() onPageChange(page: string): string {
        return page;
    }

    headers = [
        {
            text: '権限',
            align: 'left',
            width: '80px',
            sortable: false,
            value: 'isOwner',
        },
        { text: '名前', align: 'left', sortable: false, value: 'groupName' },
        { text: 'ID', align: 'left', sortable: false, value: 'system' },
        { text: '', width: '220px', sortable: false, value: 'action' },
    ];

    get groupId(): number {
        return groupMemberStore.groupId;
    }

    get groupMemberList(): GroupMember[] {
        return groupMemberStore.groupMemberList;
    }

    get groupRoleList(): GroupRole[] {
        return groupRoleStore.groupRoleList;
    }

    get isAdmin(): boolean {
        const groupMember = this.groupMemberList.find(gm => {
            return Number(gm.memberId) === authStore.memberId;
        });
        return groupMember ? groupMember.groupRoleId === 2 : false;
    }

    get errorMessage(): string {
        return groupRoleStore.errorMessage;
    }

    back(): void {
        this.onPageChange('groupList');
    }

    add(): void {
        groupMemberStore.extractAddGroupMember();
        this.onGroupMemberAdd();
    }

    edit(groupRoleId: number, memberId: number): void {
        groupMemberStore.extractEditGroupMember({ groupRoleId, memberId });
        this.onGroupMemberEdit();
    }

    async del(memberId: number): Promise<void> {
        const c = await Swal.fire({
            title: '削除してもよろしいですか？',
            text: '関連するデータも削除されます',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '実行する',
            cancelButtonText: 'キャンセル',
            reverseButtons: true,
        });
        if (c.value) {
            const isAuthenticated = await this.checkLoginState();
            if (!isAuthenticated) {
                return;
            }
            const result = await groupMemberStore.deleteGroupMember({
                groupId: this.groupId,
                memberIds: [memberId],
            });
            if (result) {
                groupMemberStore.getGroupMember({
                    groupId: this.groupId,
                });
                Swal.fire({
                    title: '更新しました',
                    icon: 'success',
                    html: '2秒後に自動で戻ります',
                    timer: 2000,
                });
            } else {
                Swal.fire('エラー', this.errorMessage, 'error');
            }
        }
    }

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }
}
