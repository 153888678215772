
import { Component, Vue } from 'vue-property-decorator';
Component.registerHooks(['beforeRouteEnter']);
import { Route, RawLocation } from 'vue-router';

import { authStore } from '@s/auth';
import { systemStore } from '@s/system';

import SystemEditDialog from '@co/internal/System/SystemEditDialog.vue';
import SystemList from '@co/internal/System/SystemList.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'system-management',
    components: {
        SystemEditDialog,
        SystemList,
        VButton,
    },
})
export default class SystemManagement extends Vue {
    dialog = false;
    systemList = [];

    add(): void {
        this.dialog = true;
    }

    close(): void {
        this.dialog = false;
    }

    async mounted(): Promise<void> {
        await systemStore.getSystem();
    }

    async beforeRouteEnter(
        _to: Route,
        _from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
    ): Promise<void> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            next({ path: '/login' });
            return;
        }
        next();
    }
}
