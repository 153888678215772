
import { Component, Emit, Vue } from 'vue-property-decorator';

import { Group } from '@i/group';
import { System } from '@i/system';
import { groupStore } from '@s/group';
import { groupMemberStore } from '@s/groupMember';
import { systemStore } from '@s/system';
import SystemDisplay from '@ca/System/SystemDisplay.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-list',
    components: {
        SystemDisplay,
        VButton,
    },
})
export default class GroupList extends Vue {
    headers = [
        {
            text: 'グループID',
            align: 'left',
            width: '80px',
            sortable: false,
            value: 'groupId',
        },
        {
            text: 'グループ名',
            align: 'left',
            sortable: false,
            value: 'groupName',
        },
        {
            text: 'システム',
            align: 'left',
            width: '500px',
            sortable: false,
            value: 'system',
        },
        { text: '', width: '220px', sortable: false, value: 'action' },
    ];

    @Emit() onGroupAdd(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
    @Emit() onGroupEdit(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
    @Emit() onPageChange(page: string): string {
        return page;
    }

    get groupList(): Group[] {
        return groupStore.groupList.filter(g => g.type === 1);
    }

    get systemList(): System[] {
        return systemStore.systemList;
    }

    add(): void {
        this.onGroupAdd();
    }

    edit(id: number): void {
        groupStore.extractEditGroup(id);
        this.onGroupEdit();
    }

    groupMemberPage(id: number): void {
        groupMemberStore.getGroupMember({ groupId: id }).then(() => {
            this.onPageChange('groupMemberList');
        });
    }
}
