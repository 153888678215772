import { Vue } from 'vue-property-decorator';
import vuetify from '@/plugins/vuetify';

import App from '@c/pages/App.vue';
import router from '@r/app';
import store from '@s/store';

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
