import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"headline theme--light primary white--text pt-2 pb-2",attrs:{"primary-title":""}},[_vm._v(" 所属追加 ")]),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"mt-4 mb-2",attrs:{"rules":_vm.nameRules,"hint":"128文字以内","label":"所属名","required":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),(_vm.isEdit)?_c(VSelect,{attrs:{"items":_vm.status,"item-text":"name","item-value":"value","menu-props":{ maxHeight: '300' },"label":"ステータス"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}):_vm._e()],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('v-button',{attrs:{"mdi":"close","word":"閉じる","text":""},on:{"on-click":_vm.close}}),_c('v-button',{attrs:{"mdi":"check","color":_vm.validationForm() ? 'primary' : '',"disabled":!_vm.validationForm() || _vm.loading,"loading":_vm.loading,"word":"保存する","text":""},on:{"on-click":function($event){_vm.isEdit ? _vm.edit() : _vm.add()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }