
import { Component, Prop, Vue } from 'vue-property-decorator';

import { System } from '@i/system';

@Component({
    name: 'system-display',
    components: {},
})
export default class SystemDisplay extends Vue {
    @Prop() systemIds!: number[];
    @Prop() systemList!: System[];

    get display(): string {
        return this.systemList
            .filter(s => this.systemIds.includes(s.systemId))
            .map(s => s.systemName)
            .join(', ');
    }
}
