
import { Component, Vue, Watch } from 'vue-property-decorator';

import { authStore } from '@s/auth';

import AppBar from '@co/AppBar.vue';
import DrawerMenu from '@co/DrawerMenu.vue';
import { platforms } from '@/store/platforms.store';

@Component({
    name: 'entry-app',
    components: {
        AppBar,
        DrawerMenu,
    },
})
export default class EntryApp extends Vue {
    error = false;
    isLoading = false;

    get isLoggedIn(): boolean {
        return authStore.isLoggedIn;
    }

    get title(): string {
        return this.$route.meta.title || '';
    }

    public get hasPlatforms(): boolean {
        return platforms.hasPlatforms;
    }

    async created(): Promise<void> {
        if (this.$route.meta.error) {
            this.error = true;
            return;
        }
    }

    @Watch('title', { immediate: true })
    onChangeTitle(newVal: string | undefined): void {
        document.title = `アカウント - ${newVal}`;
    }

    @Watch('isLoggedIn')
    public onLogin(newValue: boolean): void {
        if (newValue) {
            platforms.getPlatforms();
        }
    }
}
