import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { GroupRole } from '@i/groupRole';
import { apiUrl } from '@l/api';
import { FailureResponse, get } from '@l/axios-helper';
import store from '@s/store';
import { AxiosError } from 'axios';

@Module({ dynamic: true, store, name: 'groupRole', namespaced: true })
class GroupRoleStore extends VuexModule {
    errorMessage = '';
    groupRoleList: GroupRole[] = [];
    page = '';

    @Action({ commit: 'setGroupRoleList', rawError: true })
    public async getGroupRole(): Promise<GroupRole[]> {
        try {
            const response = await get(`${apiUrl}/group_role`);

            return response.data.data.groupRole;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to get group role');
            }
        }

        return [];
    }

    @Action({ commit: 'setPage', rawError: true })
    public setPageName(p: string | undefined): string {
        return p || '';
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setGroupRoleList(list: GroupRole[]): void {
        this.groupRoleList = list;
    }

    @Mutation
    public setPage(p: string): void {
        this.page = p;
    }
}

export const groupRoleStore = getModule(GroupRoleStore);
