
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { Member } from '@i/member';

@Component({
    name: 'system-select',
    components: {},
})
export default class SystemSelect extends Vue {
    @Prop({ type: String }) label!: string;
    @Prop({}) memberList!: Member[];
    @Prop({ default: false }) readonly!: boolean;
    @Prop({}) value!: number[];

    @Emit() input(value: number[]): number[] {
        return value;
    }

    get selectedMemberIds(): number[] {
        return this.value;
    }

    set selectedMemberIds(value: number[]) {
        this.input(value);
    }

    get allSelectFlag(): boolean {
        return this.selectedMemberIds.length === Object.keys(this.memberList).length;
    }

    toggle(): void {
        this.$nextTick(() => {
            if (this.allSelectFlag) {
                this.selectedMemberIds = [];
            } else {
                this.selectedMemberIds = this.memberList.map((v: Member) => v.memberId);
            }
        });
    }
}
