
import { Component, Prop, Vue } from 'vue-property-decorator';

import { authStore } from '@s/auth';

@Component({
    name: 'drawer-menu',
})
export default class DrawerMenu extends Vue {
    @Prop({ type: Boolean, required: false, default: () => false })
    public readonly hasPlatforms!: boolean;

    menu = [
        {
            title: '管理',
            icon: 'lock',
            children: [
                {
                    title: 'メンバー管理',
                    to: '/member_management',
                },
                {
                    title: 'システム管理',
                    to: '/system_management',
                },
                {
                    title: '所属管理',
                    to: '/user_management',
                },
                {
                    title: 'グループ管理',
                    to: '/group_management',
                },
            ],
        },
    ];

    public readonly platformMenus = [
        {
            title: 'プラットフォーム',
            icon: 'database',
            children: [
                {
                    title: 'プラットフォーム管理',
                    to: '/platforms',
                },
            ],
        },
    ];

    get isOpened(): boolean {
        return authStore.isOpened;
    }

    set isOpened(f: boolean) {
        authStore.setIsOpened(f);
    }
}
