import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { AddGroup, EditGroup, Group } from '@i/group';
import { apiUrl } from '@l/api';
import { FailureResponse, get, post, put } from '@l/axios-helper';
import store from '@s/store';
import { AxiosError } from 'axios';

const EDIT_GROUP_INIT = {
    groupId: 0,
    groupName: '',
    systemIds: [],
};

@Module({ dynamic: true, store, name: 'group', namespaced: true })
class GroupStore extends VuexModule {
    errorMessage = '';
    groupList: Group[] = [];
    editGroup: EditGroup = EDIT_GROUP_INIT;
    page = '';

    @Action({ commit: 'setGroupList', rawError: true })
    public async getGroup(): Promise<Group[]> {
        try {
            const response = await get(`${apiUrl}/group`);

            return response.data.data.group;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to get group');
            }
        }

        return [];
    }

    @Action({ rawError: true })
    public async addGroup(args: AddGroup): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/group`, args);

            if (response.data.code === '0000') {
                return true;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add group');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async updateGroup(args: EditGroup): Promise<boolean> {
        try {
            const response = await put(`${apiUrl}/group`, args);

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to update group');
            }
        }

        return false;
    }

    @Action({ commit: 'setEditGroup', rawError: true })
    public extractEditGroup(id?: number): EditGroup | null {
        if (typeof id === 'undefined') {
            return null;
        }

        const g: Group | undefined = this.groupList.find((v: Group) => v.groupId === id);

        if (!g) {
            return null;
        }

        return {
            groupId: g.groupId,
            groupName: g.groupName,
            systemIds: g.systemIds,
        };
    }

    @Action({ commit: 'setPage', rawError: true })
    public setPageName(p: string | undefined): string {
        return p || '';
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setGroupList(list: Group[]): void {
        this.groupList = list;
    }

    @Mutation
    public setEditGroup(obj: EditGroup): void {
        this.editGroup = obj;
    }

    @Mutation
    public setPage(p: string): void {
        this.page = p;
    }
}

export const groupStore = getModule(GroupStore);
