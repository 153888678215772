import { ValidatorGenerator } from '@i/validators/form';
import validateEmail from 'filter-validate-email'; // https://github.com/mpyw/FILTER_VALIDATE_EMAIL.js

export const integer: ValidatorGenerator =
    (name = 'このフィールド') =>
    v =>
        /^\d*$/.test(v) || `${name}は整数で入力してください`;

export const maxLength: (_: number) => ValidatorGenerator =
    (max: number) =>
    (name = 'このフィールド') =>
    v =>
        v.length <= max || `${name}は${max}文字以内で入力してください`;

export const minLength: (_: number) => ValidatorGenerator =
    (min: number) =>
    (name = 'このフィールド') =>
    v =>
        v.length >= min || `${name}は${min}文字以上で入力してください`;

export const required: ValidatorGenerator =
    (name = 'このフィールド') =>
    v =>
        !!v || `${name}は必須です`;

export const email: ValidatorGenerator =
    (name = 'このフィールド') =>
    v =>
        validateEmail(v) || `${name}はメールアドレスで入力してください`;

export const password: ValidatorGenerator =
    (name = 'このフィールド') =>
    v =>
        /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-.:-@[-`{-~])[!-~]{1,}/.test(v) ||
        `${name}は半角英小文字大文字数字記号をそれぞれ1文字以上で入力してください`;

export const regexp: (_e: RegExp) => ValidatorGenerator =
    reg =>
    (field = 'このフィールド') =>
    v =>
        reg.test(v) || `${field}の形式が不正です`;
