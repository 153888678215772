import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSave && _vm.isShow),expression:"!isSave && isShow"}]},[_c(VCardTitle,{staticClass:"headline theme--light primary white--text pt-2 pb-2",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.groupName)+"：メンバー権限編集 ")]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"mt-6",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VSelect,{attrs:{"items":_vm.memberList,"item-text":"memberName","item-value":"memberId","menu-props":{ maxHeight: '400' },"label":"メンバー","readonly":"","multiple":""},model:{value:(_vm.memberId),callback:function ($$v) {_vm.memberId=$$v},expression:"memberId"}}),_c('group-role-select',{attrs:{"label":"グループ権限","group-role-list":_vm.groupRoleList},model:{value:(_vm.groupRoleId),callback:function ($$v) {_vm.groupRoleId=$$v},expression:"groupRoleId"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('v-button',{attrs:{"mdi":"close","word":"閉じる","text":""},on:{"on-click":_vm.close}}),_c('v-button',{attrs:{"mdi":"check","color":_vm.validationForm() ? 'primary' : '',"disabled":!_vm.validationForm() || _vm.loading,"loading":_vm.loading,"word":"保存する","text":""},on:{"on-click":function($event){return _vm.update()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }