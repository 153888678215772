
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { System } from '@i/system';

@Component({
    name: 'system-select',
    components: {},
})
export default class SystemSelect extends Vue {
    @Prop({ type: String }) label!: string;
    @Prop({}) systemList!: System[];
    @Prop({}) value!: number[];

    @Emit() input(value: number[]): number[] {
        return value;
    }

    get selectedSystemIds(): number[] {
        return this.value;
    }

    set selectedSystemIds(value: number[]) {
        this.input(value);
    }

    get allSelectFlag(): boolean {
        return this.selectedSystemIds.length === Object.keys(this.systemList).length;
    }

    toggle(): void {
        this.$nextTick(() => {
            if (this.allSelectFlag) {
                this.selectedSystemIds = [];
            } else {
                this.selectedSystemIds = this.systemList.map((v: System) => v.systemId);
            }
        });
    }
}
