
import { Component, Vue } from 'vue-property-decorator';

import { User } from '@i/user';

import { memberStore } from '@s/member';
import { userStore } from '@s/user';

@Component({
    name: 'search-member',
    components: {},
})
export default class SearchMember extends Vue {
    get searchMember(): string {
        return memberStore.searchMember;
    }

    set searchMember(value: string) {
        memberStore.setSearchMember(value);
    }

    get searchUser(): number[] {
        return memberStore.searchUser;
    }

    set searchUser(value: number[]) {
        memberStore.setSearchUser(value);
    }

    get userList(): User[] {
        return userStore.userList;
    }

    get allSelectFlag(): boolean {
        return this.searchUser.length === Object.keys(this.userList).length;
    }

    toggle(): void {
        this.$nextTick(() => {
            if (this.allSelectFlag) {
                this.searchUser = [];
            } else {
                this.searchUser = this.userList.map((v: User) => v.userId);
            }
        });
    }
}
