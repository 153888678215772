
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { Group } from '@i/group';
import { GroupRole } from '@i/groupRole';
import { Member } from '@i/member';
import { ExternalParam } from '@i/auth';
import { AddGroupMember } from '@i/groupMember';
import { maxLength, required } from '@l/validators/basic';
import { authStore } from '@s/auth';
import { groupStore } from '@s/group';
import { groupRoleStore } from '@s/groupRole';
import { groupMemberStore } from '@s/groupMember';
import { memberStore } from '@s/member';
import GroupRoleSelect from '@ca/GroupRole/GroupRoleSelect.vue';
import MemberSelect from '@ca/Member/MemberSelect.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-member-add',
    components: {
        GroupRoleSelect,
        MemberSelect,
        VButton,
    },
})
export default class GroupMemberAdd extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;

    dummy = '';
    isSave = false; // 保存後かどうか
    isShow = false; // 全体の表示(初期遷移時)
    loading = false;
    groupId = 0;
    groupRoleId = 0;
    memberIds: number[] = [];
    nameRules = [required, maxLength(20)].map(v => v('グループ名'));
    valid = false; // バリデーションエラーの有無

    get group(): Group | undefined {
        return groupStore.groupList.find(g => g.groupId === this.groupId);
    }

    get groupName(): string {
        return this.group ? this.group.groupName : '---';
    }

    get groupMemberList(): number[] {
        return groupMemberStore.groupMemberList.map(gm => gm.memberId);
    }

    get groupRoleList(): GroupRole[] {
        return groupRoleStore.groupRoleList;
    }

    get memberList(): Member[] {
        return memberStore.memberList.filter(m => !this.groupMemberList.includes(m.memberId));
    }

    get addGroupMember(): AddGroupMember {
        return groupMemberStore.addGroupMember;
    }

    get externalParam(): ExternalParam {
        return authStore.externalParam;
    }

    get errorMessage(): string {
        return groupStore.errorMessage;
    }

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async add(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            groupId: this.groupId,
            groupRoleId: this.groupRoleId,
            memberIds: this.memberIds,
        };

        const result = await groupMemberStore.postGroupMember(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.close();
            this.isSave = true;
            groupMemberStore.getGroupMember({
                groupId: this.groupId,
            });
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', this.errorMessage, 'error');
        }
    }

    back(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }

    validationForm(): boolean {
        return this.valid && this.memberIds.length > 0;
    }

    init(): void {
        this.isSave = false;
        this.isShow = true;
        this.groupId = this.addGroupMember.groupId;
        this.groupRoleId = 1;
        this.memberIds = this.addGroupMember.memberIds;
    }

    created(): void {
        this.init();
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
            this.init();
        }
    }
}
