
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { EditUser, Status } from '@i/user';
import { maxLength, required } from '@l/validators/basic';
import { authStore } from '@s/auth';
import { userStore } from '@s/user';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'user-edit',
    components: {
        VButton,
    },
})
export default class UserEdit extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    loading = false;
    nameRules = [required, maxLength(128)].map(v => v('所属名'));
    selectedStatus = 1;
    userName = '';
    valid = false; // バリデーションエラーの有無

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    get editUser(): EditUser {
        return userStore.editUser;
    }

    get status(): { [key: number]: Status } {
        return userStore.status;
    }

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async add(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            userName: this.userName,
        };

        const result = await userStore.addUser(args);
        this.afterSaving(result);
    }

    async edit(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            userId: this.editUser.userId,
            userName: this.userName,
            status: this.selectedStatus,
        };

        const result = await userStore.updateUser(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.close();
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', userStore.errorMessage, 'error');
        }
    }

    init(): void {
        this.userName = '';
        this.selectedStatus = 1;
        if (this.editUser.userId !== 0) {
            this.userName = this.editUser.userName;
            this.selectedStatus = this.editUser.status;
        }
    }

    close(): void {
        this.onClick();
    }

    validationForm(): boolean {
        return this.valid && this.userName != '';
    }

    created(): void {
        this.init();
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
            this.init();
        }
    }
}
