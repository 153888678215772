
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import GroupMemberAdd from '@cm/internal/GroupMember/GroupMemberAdd.vue';

@Component({
    name: 'group-member-add-dialog',
    components: {
        GroupMemberAdd,
    },
})
export default class GroupMemberAddDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }
}
