import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { GroupMember, GetGroupMember, AddGroupMember, EditGroupMember, DeleteGroupMember } from '@i/groupMember';
import { apiUrl } from '@l/api';
import { get, post, put, delete_, FailureResponse } from '@l/axios-helper';
import store from '@s/store';
import { AxiosError } from 'axios';

const ADD_GROUP_MEMBER_INIT = {
    groupId: 0,
    groupRoleId: 0,
    memberIds: [],
};

const EDIT_GROUP_MEMBER_INIT = {
    groupId: 0,
    groupRoleId: 0,
    memberId: 0,
};

@Module({ dynamic: true, store, name: 'groupMember', namespaced: true })
class GroupMemberStore extends VuexModule {
    errorMessage = '';
    groupId = 0;
    groupMemberList: GroupMember[] = [];
    addGroupMember: AddGroupMember = ADD_GROUP_MEMBER_INIT;
    editGroupMember: EditGroupMember = EDIT_GROUP_MEMBER_INIT;

    @Action({ commit: 'setGroupMemberList', rawError: true })
    public async getGroupMember(data: GetGroupMember): Promise<GroupMember[]> {
        try {
            const response = await get(`${apiUrl}/group_member`, data);

            this.setGroupId(data.groupId);

            return response.data.data.member;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to get group');
            }
        }

        return [];
    }

    @Action({ rawError: true })
    public async postGroupMember(args: AddGroupMember): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/group_member`, args);

            if (response.data.code === '0000') {
                return true;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add group member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async updateGroupMember(args: EditGroupMember): Promise<boolean> {
        try {
            const response = await put(`${apiUrl}/group_member`, args);

            if (response.data.code === '0000') {
                return true;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add group member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async deleteGroupMember(args: DeleteGroupMember): Promise<boolean> {
        try {
            console.log(args);

            const response = await delete_(`${apiUrl}/group_member`, args);

            if (response.data.code === '0000') {
                return true;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to delete group member');
            }
        }

        return false;
    }

    @Action({ commit: 'setAddGroupMember', rawError: true })
    public extractAddGroupMember(): AddGroupMember {
        return {
            groupId: this.groupId,
            groupRoleId: 1,
            memberIds: [],
        };
    }

    @Action({ commit: 'setEditGroupMember', rawError: true })
    public extractEditGroupMember(args: { groupRoleId: number; memberId: number }): EditGroupMember {
        return {
            groupId: this.groupId,
            groupRoleId: args.groupRoleId,
            memberId: args.memberId,
        };
    }

    @Mutation
    public setAddGroupMember(obj: AddGroupMember): void {
        this.addGroupMember = obj;
    }

    @Mutation
    public setEditGroupMember(obj: EditGroupMember): void {
        this.editGroupMember = obj;
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setGroupId(id: number): void {
        this.groupId = id;
    }

    @Mutation
    public setGroupMemberList(list: GroupMember[]): void {
        this.groupMemberList = list;
    }
}

export const groupMemberStore = getModule(GroupMemberStore);
