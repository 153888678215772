
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { maxLength, minLength, required } from '@l/validators/basic';
import { authStore } from '@s/auth';
import { systemStore } from '@s/system';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'system-edit',
    components: {
        VButton,
    },
})
export default class SystemEdit extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;

    public readonly identifierMaxLength = 64;
    identifier = '';
    identifierRules = [required, minLength(2), maxLength(this.identifierMaxLength)].map(v => v('識別子'));
    loading = false;
    nameRules = [required, maxLength(20)].map(v => v('システム名'));
    systemName = '';
    valid = false; // バリデーションエラーの有無

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async add(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            systemName: this.systemName,
            identifier: this.identifier,
        };

        const result = await systemStore.addSystem(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.close();
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', systemStore.errorMessage, 'error');
        }
    }

    close(): void {
        this.onClick();
    }

    validationForm(): boolean {
        return this.valid && this.systemName != '' && this.identifier != '';
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
        }
    }
}
