
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { GroupRole } from '@i/groupRole';

@Component({
    name: 'group-role-select',
    components: {},
})
export default class GroupRoleSelect extends Vue {
    @Prop({ type: String }) label!: string;
    @Prop({}) groupRoleList!: GroupRole[];
    @Prop({}) value!: number;

    @Emit() input(value: number): number {
        return value;
    }

    get selectedGroupRoleId(): number {
        return this.value;
    }

    set selectedGroupRoleId(value: number) {
        this.input(value);
    }
}
