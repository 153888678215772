import { Vue } from 'vue-property-decorator';
import VueRouter, { RouteConfig } from 'vue-router';
import Error from '@cp/Error.vue';
import MemberManagement from '@ct/MemberManagement.vue';
import Login from '@ct/Login.vue';
import SystemManagement from '@ct/SystemManagement.vue';
import UserManagement from '@ct/UserManagement.vue';
import GroupManagement from '@ct/GroupManagement.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            title: 'ログイン',
        },
    },
    {
        path: '/member_management',
        component: MemberManagement,
        name: 'member_management',
        meta: {
            title: 'メンバー管理',
        },
    },
    {
        path: '/system_management',
        component: SystemManagement,
        name: 'system_management',
        meta: {
            title: 'システム管理',
        },
    },
    {
        path: '/user_management',
        component: UserManagement,
        name: 'user_management',
        meta: {
            title: '所属管理',
        },
    },
    {
        path: '/group_management',
        component: GroupManagement,
        name: 'group',
        meta: {
            title: 'グループ管理',
        },
    },
    {
        path: '/platforms',
        name: 'platforms',
        component: () => import(/* webpackChunkName: "platforms" */ '@co/internal/Platforms/PlatformList.vue'),
        meta: {
            title: 'プラットフォーム管理',
        },
    },
    // {
    //     path: '/',
    //     component: Error,
    //     name: 'error',
    //     meta: {
    //         title: 'エラー',
    //         error: true
    //     }
    // },
    {
        path: '*',
        component: Error,
        name: 'error',
        meta: {
            title: 'エラー',
            error: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});

export default router;
