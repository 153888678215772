import { render, staticRenderFns } from "./App.vue?vue&type=template&id=4e5b5522&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&id=4e5b5522&prod&scoped=true&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&id=4e5b5522&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5b5522",
  null
  
)

export default component.exports