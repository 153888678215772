
import { Vue, Component, Emit } from 'vue-property-decorator';

import { Status, User } from '@i/user';

import { userStore } from '@s/user';

import VButton from '@ca/VButton.vue';

@Component({
    name: 'user-list',
    components: {
        VButton,
    },
})
export default class UserList extends Vue {
    headers = [
        { text: 'ID', align: 'left', sortable: false, value: 'userId' },
        { text: '所属名', align: 'left', sortable: false, value: 'userName' },
        { text: 'ステータス', align: 'left', sortable: false, value: 'status' },
        { text: '', sortable: false, value: 'action' },
    ];

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    get status(): { [key: number]: Status } {
        const s: { [key: number]: Status } = {};
        for (const v of userStore.status) {
            s[v.value] = v;
        }
        return s;
    }

    get userList(): User[] {
        return userStore.userList;
    }

    edit(id: number): void {
        userStore.extractEditUser(id);
        this.onClick();
    }
}
