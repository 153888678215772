
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import MemberEdit from '@cm/internal/Member/MemberEdit.vue';

@Component({
    name: 'member-edit-dialog',
    components: {
        MemberEdit,
    },
})
export default class MemberEditDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }
}
