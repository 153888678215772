import axios from 'axios';

import { Service } from 'axios-middleware';
import { authStore } from '@s/auth';

const service = new Service(axios);

service.register({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onRequest(config: any): Promise<any> {
        const token = authStore.accessToken;
        config.headers['Authorization'] = `Bearer ${token}`;
        if (!config.data) {
            config.data = {};
        }

        return config;
    },
});

export default axios;
