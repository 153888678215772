
import { Component, Vue } from 'vue-property-decorator';
Component.registerHooks(['beforeRouteEnter']);

import { Route, RawLocation } from 'vue-router';

import { authStore } from '@s/auth';

import VButton from '@ca/VButton.vue';

@Component({
    name: 'login',
    components: {
        VButton,
    },
})
export default class Login extends Vue {
    isPassShow = false;
    loading = false;
    loginId = '';
    password = '';

    get error(): string {
        return authStore.errorMessage;
    }

    get isError(): boolean {
        return authStore.isError;
    }

    async login(): Promise<void> {
        this.loading = true;

        const args = {
            loginId: this.loginId,
            password: this.password,
            identifier: 'jt',
        };

        const res = await authStore.login(args);
        this.loading = false;
        if (!res) {
            return;
        }

        this.$router.push(
            '/member_management',
            () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
            () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
        );
    }

    trigger(e: KeyboardEvent): void {
        if (e.keyCode === 13) {
            this.login();
        }
    }

    async beforeRouteEnter(
        _to: Route,
        _from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
    ): Promise<void> {
        await authStore.checkLoginState();
        if (authStore.isLoggedIn) {
            next({ path: '/member_management' });
            return;
        }

        next();
    }
}
