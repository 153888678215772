import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSave && _vm.isShow),expression:"!isSave && isShow"}]},[_c(VCardTitle,{staticClass:"headline theme--light primary white--text pt-2 pb-2",attrs:{"primary-title":""}},[_vm._v(" グループ"+_vm._s(_vm.isEdit ? '編集' : '追加')+" ")]),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"mt-4 mb-1",attrs:{"rules":_vm.nameRules,"hint":"20文字以内","label":"グループ名","required":""},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}}),_c('system-select',{attrs:{"label":"グループで利用するシステム","system-list":_vm.systemList},model:{value:(_vm.systemIds),callback:function ($$v) {_vm.systemIds=$$v},expression:"systemIds"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('v-button',{attrs:{"mdi":"close","word":"閉じる","text":""},on:{"on-click":_vm.close}}),_c('v-button',{attrs:{"mdi":"check","color":_vm.validationForm() ? 'primary' : '',"disabled":!_vm.validationForm() || _vm.loading,"loading":_vm.loading,"word":"保存する","text":""},on:{"on-click":function($event){_vm.isEdit ? _vm.update() : _vm.add()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }