import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSelect,{attrs:{"items":_vm.systemList,"item-text":"systemName","item-value":"systemId","menu-props":{ maxHeight: '400' },"label":_vm.label,"multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":_vm.selectedSystemIds.length > 0 ? 'primary--text theme--light' : ''}},[_vm._v(" "+_vm._s(_vm.allSelectFlag ? 'mdi-close-box' : 'mdi-minus-box')+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("全選択")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function({ item, index }){return [(_vm.selectedSystemIds.length === 1)?_c(VChip,{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.systemName))])]):_vm._e(),(_vm.selectedSystemIds.length > 1 && index === 1)?_c(VChip,{staticClass:"d-block text-center w-80",attrs:{"small":""}},[_vm._v(" "+_vm._s(`${_vm.selectedSystemIds.length}個選択中`)+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedSystemIds),callback:function ($$v) {_vm.selectedSystemIds=$$v},expression:"selectedSystemIds"}})
}
var staticRenderFns = []

export { render, staticRenderFns }