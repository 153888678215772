import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"headline theme--light primary white--text pt-2 pb-2",attrs:{"primary-title":""}},[_vm._v(" システム追加 ")]),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"mt-4 mb-2",attrs:{"rules":_vm.nameRules,"hint":`${_vm.identifierMaxLength}文字以内`,"label":"システム名","required":""},model:{value:(_vm.systemName),callback:function ($$v) {_vm.systemName=$$v},expression:"systemName"}}),_c(VTextField,{staticClass:"mt-4 mb-2",attrs:{"rules":_vm.identifierRules,"hint":"2文字以上32文字以内で、半角英数またはハイフンが使用できます","label":"識別子","required":""},model:{value:(_vm.identifier),callback:function ($$v) {_vm.identifier=$$v},expression:"identifier"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('v-button',{attrs:{"mdi":"close","word":"閉じる","text":""},on:{"on-click":_vm.close}}),_c('v-button',{attrs:{"mdi":"check","color":_vm.validationForm() ? 'primary' : '',"disabled":!_vm.validationForm() || _vm.loading,"loading":_vm.loading,"word":"保存する","text":""},on:{"on-click":function($event){return _vm.add()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }