
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { Member } from '@i/member';
import { Group } from '@i/group';
import { GroupRole } from '@i/groupRole';
import { ExternalParam } from '@i/auth';
import { EditGroupMember } from '@i/groupMember';
import { authStore } from '@s/auth';
import { memberStore } from '@s/member';
import { groupStore } from '@s/group';
import { groupMemberStore } from '@s/groupMember';
import { groupRoleStore } from '@s/groupRole';
import GroupRoleSelect from '@ca/GroupRole/GroupRoleSelect.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-member-edit',
    components: {
        GroupRoleSelect,
        VButton,
    },
})
export default class GroupMemberEdit extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    dummy = '';
    isSave = false; // 保存後かどうか
    isShow = false; // 全体の表示(初期遷移時)
    loading = false;
    groupId = 0;
    groupRoleId = 0;
    memberId = 0;
    valid = false; // バリデーションエラーの有無

    get group(): Group | undefined {
        return groupStore.groupList.find(g => g.groupId === this.groupId);
    }

    get groupName(): string {
        return this.group ? this.group.groupName : '---';
    }

    get editGroupMember(): EditGroupMember {
        return groupMemberStore.editGroupMember;
    }

    get groupRoleList(): GroupRole[] {
        return groupRoleStore.groupRoleList;
    }

    get memberList(): Member[] {
        return memberStore.memberList;
    }

    get externalParam(): ExternalParam {
        return authStore.externalParam;
    }

    get errorMessage(): string {
        return groupMemberStore.errorMessage;
    }

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async update(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            groupId: this.groupId,
            groupRoleId: this.groupRoleId,
            memberId: this.memberId,
        };

        const result = await groupMemberStore.updateGroupMember(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.close();
            this.isSave = true;
            groupMemberStore.getGroupMember({
                groupId: this.groupId,
            });
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', this.errorMessage, 'error');
        }
    }

    back(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }

    validationForm(): boolean {
        return this.valid;
    }

    init(): void {
        this.isSave = false;
        this.isShow = true;
        this.groupId = this.editGroupMember.groupId;
        this.groupRoleId = this.editGroupMember.groupRoleId;
        this.memberId = this.editGroupMember.memberId;
    }

    created(): void {
        this.init();
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
            this.init();
        }
    }
}
