import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"app":""},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('div',{staticClass:"pl-9 mr-n4",staticStyle:{"width":"256px","padding-top":"3px"}},[_c('img',{attrs:{"src":"images/logo.svg","alt":"アカウント","height":"40"}})]),_c(VDivider),_vm._l((_vm.menu),function(m){return _c('div',{key:m.title},[_c(VListItem,[_c(VListItemIcon,[_c('i',{staticClass:"v-icon notranslate v-icon--left mdi theme--light",class:`mdi-${m.icon}`,attrs:{"aria-hidden":"true"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(m.title))])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((m.children),function(c){return _c(VListItem,{key:c.title,attrs:{"to":c.to,"active-class":"green--text","link":""}},[_c(VListItemIcon,[_c('i',{staticClass:"v-icon notranslate v-icon--left mdi mdi-chevron-right theme--light",attrs:{"aria-hidden":"true"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(c.title)+" ")])],1)],1)}),1)],1)}),(_vm.hasPlatforms)?_vm._l((_vm.platformMenus),function(m){return _c('div',{key:m.title},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(`mdi-${m.icon}`))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(m.title))])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((m.children),function(c){return _c(VListItem,{key:c.title,attrs:{"to":c.to,"active-class":"green--text","link":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-right")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(c.title)+" ")])],1)],1)}),1)],1)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }