import { Either, map } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { GetPlatformsError } from '../domain/platforms/errors/get.error';
import { PlatformFactory, RawPlatform } from '../domain/platforms/factories/platform.factory';
import { Platform } from '../domain/platforms/objects/platform';
import { ApiClient } from './api.service';

export interface CreatePlatformRequest {
    name: string;
    hostname: string;
    prefix: string;
    loginId: string;
    password: string;
}

export class PlatformsService {
    private readonly baseEndpoint = 'platforms';

    public constructor(private readonly api: ApiClient) {}

    public async getPlatforms(): Promise<Either<GetPlatformsError, Platform[]>> {
        return pipe(
            await this.api.requestWithError<RawPlatform[], unknown, GetPlatformsError>(
                'get',
                this.baseEndpoint,
                error => {
                    console.log(error);
                    return 'no-permission';
                },
            ),
            map(p => p.map(PlatformFactory.fromRaw)),
        );
    }

    public async createPlatform(request: CreatePlatformRequest): Promise<Either<string, Platform>> {
        type Param = Omit<CreatePlatformRequest, 'adminLoginId' | 'adminPassword'> & {
            admin_login_id: string; // eslint-disable-line camelcase
            admin_password: string; // eslint-disable-line camelcase
        };
        return pipe(
            await this.api.requestWithError<RawPlatform, Param, string>(
                'post',
                this.baseEndpoint,
                error => error.message,
                {
                    ...request,
                    admin_login_id: request.loginId, // eslint-disable-line camelcase
                    admin_password: request.password, // eslint-disable-line camelcase
                },
            ),
            map(PlatformFactory.fromRaw),
        );
    }
}
