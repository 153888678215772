import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@s/store';
import { apiUrl } from '@l/api';
import { FailureResponse, get, post, put } from '@l/axios-helper';
import { EditUser, User } from '@i/user';
import { AxiosError } from 'axios';

const EDIT_USER_INIT = {
    userId: 0,
    userName: '',
    status: 1,
};

const STATUS = [
    { value: 1, name: '利用中', color: 'primary' },
    { value: 2, name: '一時停止', color: 'warning' },
    { value: 3, name: '削除', color: 'error' },
];

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class UserStore extends VuexModule {
    editUser: EditUser = EDIT_USER_INIT;
    errorMessage = '';
    userList: User[] = [];
    status = STATUS;

    @Action({ rawError: true })
    public async addUser(args: { userName: string }): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/user`, args);
            this.getUser();

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to add user`);
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async updateUser(args: { userId: number; userName: string; status: number }): Promise<boolean> {
        try {
            const response = await put(`${apiUrl}/user`, args);
            this.getUser();

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to edit user`);
            }
        }

        return false;
    }

    @Action({ commit: 'setEditUser', rawError: true })
    public extractEditUser(id?: number): User {
        if (typeof id === 'undefined') {
            return EDIT_USER_INIT;
        }

        return this.userList.filter((v: User) => v.userId === id)[0];
    }

    @Action({ commit: 'setUserList', rawError: true })
    public async getUser(): Promise<User[]> {
        try {
            const response = await get(`${apiUrl}/user`);

            return response.data.data.user;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to login (${err.response?.data.code})`);
            }
        }

        return [];
    }

    @Mutation
    public setEditUser(obj: EditUser): void {
        this.editUser = obj;
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setUserList(list: User[]): void {
        this.userList = list;
    }
}

export const userStore = getModule(UserStore);
