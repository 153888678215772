
import { Component, Vue } from 'vue-property-decorator';
Component.registerHooks(['beforeRouteEnter']);
import { Route, RawLocation } from 'vue-router';

import { authStore } from '@s/auth';
import { userStore } from '@s/user';

import UserEditDialog from '@co/internal/User/UserEditDialog.vue';
import UserList from '@co/internal/User/UserList.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'user-management',
    components: {
        UserEditDialog,
        UserList,
        VButton,
    },
})
export default class UserManagement extends Vue {
    dialog = false;
    isEdit = false;
    systemList = [];

    add(): void {
        userStore.extractEditUser();
        this.isEdit = false;
        this.dialog = true;
    }

    close(): void {
        this.dialog = false;
    }

    edit(): void {
        this.isEdit = true;
        this.dialog = true;
    }

    async mounted(): Promise<void> {
        await userStore.getUser();
    }

    async beforeRouteEnter(
        _to: Route,
        _from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
    ): Promise<void> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            next({ path: '/login' });
            return;
        }
        next();
    }
}
