import { ApiClient } from '@/models/services/api.service';
import { PlatformsService } from '@/models/services/platforms.service';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './store';

@Module({ name: 'api', namespaced: true, dynamic: true, store })
class ApiStore extends VuexModule {
    private readonly api = new ApiClient();
    public readonly platforms = new PlatformsService(this.api);

    @Action({ rawError: true })
    public async setToken(request: { token: string }): Promise<void> {
        this._setToken(request.token);
    }

    @Mutation
    private _setToken(token: string | null): void {
        if (token == null) {
            this.api.clearToken();
        } else {
            this.api.setToken(token);
        }
    }
}

export const api = getModule(ApiStore);
