
import { Component, Vue } from 'vue-property-decorator';
Component.registerHooks(['beforeRouteEnter']);
import { Route, RawLocation } from 'vue-router';

import { authStore } from '@s/auth';
import { groupStore } from '@s/group';
import { groupRoleStore } from '@s/groupRole';
import { memberStore } from '@s/member';
import { systemStore } from '@s/system';

import GroupEditDialog from '@co/internal/Group/GroupEditDialog.vue';
import GroupMemberList from '@co/internal/GroupMember/GroupMemberList.vue';
import GroupMemberAddDialog from '@co/internal/GroupMember/GroupMemberAddDialog.vue';
import GroupMemberEditDialog from '@co/internal/GroupMember/GroupMemberEditDialog.vue';
import GroupList from '@co/internal/Group/GroupList.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-management',
    components: {
        GroupEditDialog,
        GroupMemberList,
        GroupMemberAddDialog,
        GroupMemberEditDialog,
        GroupList,
        VButton,
    },
})
export default class GroupManagement extends Vue {
    groupEditDialog = false;
    isGroupEdit = false;
    groupMemberAddDialog = false;
    groupMemberEditDialog = false;
    isGroupMemberEdit = false;
    isLoaded = false;
    page = 'groupList';

    async groupAdd(): Promise<void> {
        groupStore.extractEditGroup();
        this.isGroupEdit = false;
        this.groupEditDialog = true;
    }

    groupEdit(): void {
        this.isGroupEdit = true;
        this.groupEditDialog = true;
    }

    groupClose(): void {
        this.groupEditDialog = false;
    }

    pageChange(p: string): void {
        this.page = p;
    }

    groupMemberAdd(): void {
        this.groupMemberAddDialog = true;
    }

    groupMemberEdit(): void {
        this.isGroupMemberEdit = true;
        this.groupMemberEditDialog = true;
    }

    groupMemberAddClose(): void {
        this.groupMemberAddDialog = false;
    }

    groupMemberEditClose(): void {
        this.groupMemberEditDialog = false;
    }

    async mounted(): Promise<void> {
        Promise.all([
            groupStore.getGroup(),
            groupRoleStore.getGroupRole(),
            memberStore.getMember(),
            systemStore.getSystem(),
        ]).then(() => {
            this.isLoaded = true;
        });
    }

    async beforeRouteEnter(
        _to: Route,
        _from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
    ): Promise<void> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            next({ path: '/login' });
            return;
        }
        next();
    }
}
