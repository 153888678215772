
import { Vue, Component } from 'vue-property-decorator';
import { baseUrl } from '@l/api';

@Component({
    name: 'error',
})
export default class Error extends Vue {
    currentUrl = '';

    mounted(): void {
        document.title = `${this.$route.meta.title}`;
        this.currentUrl = `${baseUrl}${this.$route.path}`;
    }
}
