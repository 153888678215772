import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@s/store';
import { Platform } from '@/models/domain/platforms/objects/platform';
import { api } from './api.store';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Either';
import { CreatePlatformRequest } from '@/models/services/platforms.service';

@Module({ dynamic: true, store, name: 'platforms', namespaced: true })
class PlatformsStore extends VuexModule {
    public platforms: Platform[] = [];
    public loading = false;
    public errorMessage: string | null = null;

    private readonly api = api.platforms;

    public get hasPlatforms(): boolean {
        return this.platforms.length > 0;
    }

    public get hasError(): boolean {
        return this.errorMessage != null;
    }

    @Action({ rawError: true })
    public async getPlatforms(): Promise<void> {
        await this.startLoading();
        await this.clearErrorMessage();
        pipe(
            await this.api.getPlatforms(),
            fold(e => {
                console.log(e);
                // パーミッションがないエラーのときは無視する
            }, this.setPlatforms),
        );

        await this.stopLoading();
    }

    @Action({ rawError: true })
    public async createPlatform(request: CreatePlatformRequest): Promise<void> {
        await this.startLoading();
        await this.clearErrorMessage();

        pipe(
            await this.api.createPlatform(request),
            fold(this.setErrorMessage, p => this.setPlatforms([...this.platforms, p])),
        );

        await this.stopLoading();
    }

    @Action({ rawError: true })
    private async startLoading(): Promise<void> {
        this.setLoading(true);
    }

    @Action({ rawError: true })
    private async stopLoading(): Promise<void> {
        this.setLoading(false);
    }

    @Action({ rawError: true })
    private async clearErrorMessage(): Promise<void> {
        this.setErrorMessage(null);
    }

    @Mutation
    private setPlatforms(platforms: Platform[]): void {
        this.platforms = platforms;
    }

    @Mutation
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @Mutation
    private setErrorMessage(errorMessage: string | null): void {
        this.errorMessage = errorMessage;
    }
}

export const platforms = getModule(PlatformsStore);
