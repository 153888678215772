
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { Callback } from '@i/callback';
import { ExternalParam } from '@i/auth';
import { EditMember } from '@i/member';
import { Group } from '@i/group';
import { User } from '@i/user';
import { System } from '@i/system';
import { FormValidator } from '@i/validators/form';
import { email, maxLength, minLength, password, required } from '@l/validators/basic';
import { authStore } from '@s/auth';
import { groupStore } from '@s/group';
import { groupMemberStore } from '@s/groupMember';
import { callbackStore } from '@s/callback';
import { memberStore } from '@s/member';
import { userStore } from '@s/user';
import { systemStore } from '@s/system';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'member-edit',
    components: {
        VButton,
    },
})
export default class MemberEdit extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    public readonly nameMaxLength = 64;

    dummy = '';
    emailRules = [required, email].map(v => v('ログインID'));
    isSave = false; // 保存後かどうか
    isShow = false; // 全体の表示(初期遷移時)
    isPassShow = false; // パスワードの表示
    loading = false;
    loginId = '';
    loginPassword = '';
    memberId = 0;
    memberName = '';
    nameRules = [required, maxLength(this.nameMaxLength)].map(v => v('メンバー名'));
    passRules = [required, password, minLength(8), maxLength(32)].map(v => v('パスワード'));
    selectedUserId = 0;
    selectedSystemIds: number[] = [];
    selectedGroupIds: number[] = [];
    systemName = '';
    valid = false; // バリデーションエラーの有無
    step = 1;

    get allSelectFlag(): boolean {
        return this.selectedSystemIds.length === Object.keys(this.systemList).length;
    }

    get groupList(): Group[] {
        return groupStore.groupList.filter(group => {
            const type = group.type === 1;
            const role = group.groupRoleId === 2;
            const selectedSystem =
                [...this.selectedSystemIds, ...group.systemIds].filter(
                    item => this.selectedSystemIds.includes(item) && group.systemIds.includes(item),
                ).length > 0;
            return type && role && selectedSystem;
        });
    }

    get callback(): Callback {
        return callbackStore.callback;
    }

    get userList(): User[] {
        return userStore.userList;
    }

    get systemList(): System[] {
        return systemStore.systemList;
    }

    get editMember(): EditMember | null {
        return memberStore.editMember;
    }

    get editPassRules(): FormValidator[] | boolean[] {
        return this.loginPassword.length === 0
            ? [].map(_ => true)
            : [password, minLength(8), maxLength(32)].map(v => v('パスワード'));
    }

    get externalParam(): ExternalParam {
        return authStore.externalParam;
    }

    get errorMessage(): string {
        return memberStore.errorMessage;
    }

    get registrantMemberId(): number {
        return authStore.memberId;
    }

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async add(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            loginId: this.loginId,
            memberName: this.memberName,
            password: this.loginPassword,
            systemIds: this.selectedSystemIds,
            userId: this.selectedUserId,
        };

        const insertMemberId = await memberStore.addMember(args);

        if (insertMemberId) {
            this.memberId = insertMemberId;

            await Promise.all(
                this.selectedGroupIds.map(groupId => {
                    return groupMemberStore.postGroupMember({
                        groupId: groupId,
                        groupRoleId: 1,
                        memberIds: [this.memberId],
                    });
                }),
            );
        }

        this.afterSaving(this.memberId ? true : false);
    }

    async update(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            memberId: this.memberId,
            memberName: this.memberName,
            password: this.loginPassword,
            systemIds: this.selectedSystemIds,
            userId: this.selectedUserId,
        };

        const result = await memberStore.updateMember(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.callbackExe();

            this.close();
            this.isSave = true;
            memberStore.getMember();
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', this.errorMessage, 'error');
        }
    }

    callbackExe(): void {
        const page = 'member_add';

        this.selectedSystemIds.forEach(async systemId => {
            await callbackStore.search({
                systemId: systemId,
                page: page,
            });

            // callback api
            if (this.callback.api !== undefined) {
                callbackStore.exec({
                    systemId: systemId,
                    page: page,
                    url: { memberId: this.memberId },
                    header: { groupIds: this.selectedGroupIds },
                    body: {
                        loginId: this.loginId,
                        memberId: this.memberId,
                        registrantId: this.registrantMemberId,
                    },
                });
            }
        });
    }

    back(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }

    keepOn(): void {
        this.init();
        this.isSave = false;
        (this.$refs.form as HTMLFormElement).resetValidation();
    }

    toggle(): void {
        this.$nextTick(() => {
            if (this.allSelectFlag) {
                this.selectedSystemIds = [];
            } else {
                this.selectedSystemIds = this.systemList.map((v: System) => v.systemId);
            }
        });
    }

    validationForm(): boolean {
        if (this.isEdit) {
            return this.valid && this.memberName != '';
        }

        return this.valid && this.loginPassword != '' && this.loginId != '' && this.memberName != '';
    }

    init(): void {
        this.isSave = false;
        this.isShow = true;
        this.loginId = '';
        this.loginPassword = '';
        this.memberName = '';
        this.selectedSystemIds = [];
        this.selectedUserId = 0;
        this.step = 1;
        if (this.editMember) {
            this.memberId = this.editMember.memberId;
            this.memberName = this.editMember.memberName;
            this.selectedSystemIds = this.editMember.systemIds !== null ? this.editMember.systemIds : [];
            this.selectedUserId = this.editMember.userId || 0;
        }
    }

    initSelectGroup(): void {
        this.selectedGroupIds = [];
        this.step++;
    }

    created(): void {
        this.init();
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
            this.init();
        }
    }
}
