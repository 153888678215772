
import { Component, Prop, Vue } from 'vue-property-decorator';

import { GroupRole } from '@i/groupRole';

@Component({
    name: 'system-display',
    components: {},
})
export default class SystemDisplay extends Vue {
    @Prop() groupRoleId!: number;
    @Prop() groupRoleList!: GroupRole[];

    get display(): string {
        const groupRole = this.groupRoleList.find(gr => {
            return gr.groupRoleId === this.groupRoleId;
        });

        return groupRole ? groupRole.name : '---';
    }
}
