
import { Component, Vue } from 'vue-property-decorator';
Component.registerHooks(['beforeRouteEnter']);
import { Route, RawLocation } from 'vue-router';

import { authStore } from '@s/auth';
import { groupStore } from '@s/group';
import { memberStore } from '@s/member';
import { systemStore } from '@s/system';
import { userStore } from '@s/user';

import MemberEditDialog from '@co/internal/Member/MemberEditDialog.vue';
import MemberList from '@co/internal/Member/MemberList.vue';
import SearchMember from '@co/internal/Member/SearchMember.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'member-management',
    components: {
        MemberEditDialog,
        MemberList,
        SearchMember,
        VButton,
    },
})
export default class MemberManagement extends Vue {
    dialog = false;
    memberList = [];
    isEdit = false;

    async add(): Promise<void> {
        memberStore.extractEditMember();
        this.isEdit = false;
        this.dialog = true;
    }

    close(): void {
        this.dialog = false;
    }

    edit(): void {
        this.isEdit = true;
        this.dialog = true;
    }

    async mounted(): Promise<void> {
        await groupStore.getGroup();
        await memberStore.getMember();
        await userStore.getUser();
        await systemStore.getSystem();
    }

    async beforeRouteEnter(
        _to: Route,
        _from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
    ): Promise<void> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            next({ path: '/login' });
            return;
        }
        next();
    }
}
