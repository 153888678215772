
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import GroupEdit from '@cm/internal/Group/GroupEdit.vue';

@Component({
    name: 'group-edit-dialog',
    components: {
        GroupEdit,
    },
})
export default class GroupEditDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }
}
