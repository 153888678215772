
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import UserEdit from '@cm/internal/User/UserEdit.vue';

@Component({
    name: 'user-edit-dialog',
    components: {
        UserEdit,
    },
})
export default class UserEditDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }
}
