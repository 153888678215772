import { Platform } from '../objects/platform';

export interface RawPlatform {
    platform_id: string; // eslint-disable-line camelcase
    name: string;
    hostname: string;
    prefix: string;
    is_default: boolean; // eslint-disable-line camelcase
}

export class PlatformFactory {
    public static fromRaw(raw: RawPlatform): Platform {
        return new Platform(raw.platform_id, raw.name, raw.hostname, raw.prefix, raw.is_default);
    }
}
