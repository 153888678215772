
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { System } from '@i/system';
import { ExternalParam } from '@i/auth';
import { EditGroup } from '@i/group';
import { maxLength, required } from '@l/validators/basic';
import { authStore } from '@s/auth';
import { groupStore } from '@s/group';
import { systemStore } from '@s/system';
import SystemSelect from '@ca/System/SystemSelect.vue';
import VButton from '@ca/VButton.vue';

@Component({
    name: 'group-edit',
    components: {
        SystemSelect,
        VButton,
    },
})
export default class GroupEdit extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;
    @Prop({ type: Boolean }) isEdit!: boolean;

    dummy = '';
    isSave = false; // 保存後かどうか
    isShow = false; // 全体の表示(初期遷移時)
    loading = false;
    groupId = 0;
    groupName = '';
    systemIds: number[] = [];
    nameRules = [required, maxLength(20)].map(v => v('グループ名'));
    valid = false; // バリデーションエラーの有無

    get systemList(): System[] {
        return systemStore.systemList;
    }

    get editGroup(): EditGroup | null {
        return groupStore.editGroup;
    }

    get externalParam(): ExternalParam {
        return authStore.externalParam;
    }

    get errorMessage(): string {
        return groupStore.errorMessage;
    }

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    @Emit() changeLoading(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async add(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            groupName: this.groupName,
            systemIds: this.systemIds,
        };

        const result = await groupStore.addGroup(args);
        this.afterSaving(result);
    }

    async update(): Promise<void> {
        this.loading = true;

        const isAuthenticated = await this.checkLoginState();
        if (!isAuthenticated) {
            return;
        }

        const args = {
            groupId: this.groupId,
            groupName: this.groupName,
            systemIds: this.systemIds,
        };

        const result = await groupStore.updateGroup(args);
        this.afterSaving(result);
    }

    afterSaving(result: boolean): void {
        this.loading = false;
        if (result) {
            this.close();
            this.isSave = true;
            groupStore.getGroup();
            Swal.fire({
                title: '更新しました',
                icon: 'success',
                html: '2秒後に自動で閉じます',
                timer: 2000,
            });
        } else {
            Swal.fire('エラー', this.errorMessage, 'error');
        }
    }

    back(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }

    validationForm(): boolean {
        return this.valid && this.groupName != '';
    }

    init(): void {
        this.isSave = false;
        this.isShow = true;
        this.groupId = 0;
        this.groupName = '';
        this.systemIds = [];
        if (this.editGroup) {
            this.groupId = this.editGroup.groupId;
            this.groupName = this.editGroup.groupName;
            this.systemIds = this.editGroup.systemIds;
        }
    }

    created(): void {
        this.init();
    }

    @Watch('dialog')
    onChangeDialog(n: boolean, o: boolean): void {
        if (n && !o) {
            (this.$refs.form as HTMLFormElement).resetValidation();
            this.init();
        }
    }
}
