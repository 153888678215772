
import { Component, Prop, Vue } from 'vue-property-decorator';

import { authStore } from '@s/auth';

import VButton from '@ca/VButton.vue';

@Component({
    name: 'app-bar',
    components: {
        VButton,
    },
})
export default class AppBar extends Vue {
    @Prop({ type: String, required: true }) readonly title!: string;

    get isOpened(): boolean {
        return authStore.isOpened;
    }

    logout(): void {
        authStore.logout();
        this.$router.push('/login');
        location.reload();
    }

    open(): void {
        authStore.setIsOpened(!this.isOpened);
    }
}
