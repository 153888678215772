
import { Vue, Component } from 'vue-property-decorator';

import { System } from '@i/system';

import { systemStore } from '@s/system';

@Component({
    name: 'system-list',
})
export default class SystemList extends Vue {
    headers = [
        { text: 'ID', align: 'left', sortable: false, value: 'systemId' },
        {
            text: 'システム名',
            align: 'left',
            sortable: false,
            value: 'systemName',
        },
        { text: '識別子', align: 'left', sortable: false, value: 'identifier' },
        {
            text: '外部連携ID',
            align: 'left',
            sortable: false,
            value: 'externalId',
        },
        {
            text: '外部連携KEY',
            align: 'left',
            sortable: false,
            value: 'externalKey',
        },
    ];

    get systemList(): System[] {
        return systemStore.systemList;
    }
}
