
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import SystemEdit from '@cm/internal/System/SystemEdit.vue';

@Component({
    name: 'system-edit-dialog',
    components: {
        SystemEdit,
    },
})
export default class SystemEditDialog extends Vue {
    @Prop({ type: Boolean }) dialog!: boolean;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    close(): void {
        this.onClick();
    }
}
