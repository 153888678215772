
import { Component, Emit, Vue } from 'vue-property-decorator';

import Swal from 'sweetalert2';

import { Member } from '@i/member';
import { User } from '@i/user';
import { System } from '@i/system';
import { authStore } from '@s/auth';
import { memberStore } from '@s/member';
import { userStore } from '@s/user';
import { systemStore } from '@s/system';

import VButton from '@ca/VButton.vue';

@Component({
    name: 'member-list',
    components: {
        VButton,
    },
})
export default class MemberList extends Vue {
    headers = [
        {
            text: 'メンバー名',
            align: 'left',
            width: '220px',
            sortable: false,
            value: 'memberName',
        },
        {
            text: 'ログインID',
            align: 'left',
            width: '350px',
            sortable: false,
            value: 'loginId',
        },
        { text: '所属', align: 'left', sortable: false, value: 'userName' },
        { text: '', sortable: false, value: 'action' },
    ];

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    get errorMessage(): string {
        return memberStore.errorMessage;
    }

    get memberId(): number {
        return authStore.memberId;
    }

    get memberList(): Member[] {
        let memberList = memberStore.memberList;

        if (memberStore.searchMember) {
            memberList = memberList.filter(member => {
                const indexMemberName =
                    member.memberName.toLowerCase().indexOf(memberStore.searchMember.toLowerCase()) >= 0;
                const indexLoginId = member.loginId.toLowerCase().indexOf(memberStore.searchMember.toLowerCase()) >= 0;
                return indexMemberName || indexLoginId;
            });
        }

        if (memberStore.searchUser.length) {
            memberList = memberList.filter(member => {
                return member.userId && memberStore.searchUser.indexOf(member.userId) >= 0;
            });
        }

        return memberList;
    }

    get userList(): User[] {
        return userStore.userList;
    }

    get systemList(): System[] {
        return systemStore.systemList;
    }

    async checkLoginState(): Promise<boolean> {
        const s = await authStore.checkLoginState();
        if (!s) {
            authStore.logout();
            authStore.setErrorMessage('セッションが切れました');
            this.$router.push('/login');
            return false;
        }

        return true;
    }

    async del(id: number): Promise<void> {
        const c = await Swal.fire({
            title: '削除してもよろしいですか？',
            text: '関連するデータも削除されます',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '実行する',
            cancelButtonText: 'キャンセル',
            reverseButtons: true,
        });
        if (c.value) {
            const isAuthenticated = await this.checkLoginState();
            if (!isAuthenticated) {
                return;
            }
            const result = await memberStore.deleteMember(id);
            if (result) {
                memberStore.getMember();
                Swal.fire({
                    title: '更新しました',
                    icon: 'success',
                    html: '2秒後に自動で戻ります',
                    timer: 2000,
                });
            } else {
                Swal.fire('エラー', this.errorMessage, 'error');
            }
        }
    }

    edit(id: number): void {
        const args = {
            id: id,
            system: this.systemList,
        };
        memberStore.extractEditMember(args);
        this.onClick();
    }

    displayUser(id: number | null): string {
        if (id === null) {
            return '---';
        }

        const idx = Object.keys(this.userList).find((k: string) => this.userList[+k].userId === id);
        if (idx === undefined) {
            return '---';
        }

        return this.userList[+idx].userName;
    }
}
